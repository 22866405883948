@media (min-width: 750px) {
    .app-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .app-content {
        display: block;
        width: 750px;
    }
}

.app-container {
    background: #f5f5f5;
    height: 100%;
}

.app-content {
    background: #f5f5f5;
    height: 100%;
}

.default-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 15px;
    color: #1a1a1a;
    font-size: 16px;
}

.default-container .center {
    justify-content: center;
    align-items: center;
}