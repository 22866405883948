/* 列表页 */

/* 去掉 tab 底部线 */

.contract .am-tabs-default-bar-tab::after {
  display: 1PX solid #EEEEEE !important;
}

.contract .am-tabs-default-bar-top .am-tabs-default-bar-tab {
  border-bottom: 1PX solid #EEEEEE;
}

/* 去掉listview样式 */

.am-pull-to-refresh {
  transform: translate3d(0, 0, 0);
}

.am-list-body {
  border-top: none;
  border-bottom: none;
  background-color: transparent !important;
}

.am-list-body::before {
  background: transparent !important;
}

.am-list-body::after {
  background: transparent !important;
}

.am-badge-text {
  min-width: 18px;
}

/* 详情页 */

.hidden {
  display: none !important;
}

.contract-tab {
  height: 44px;
  width: 100%;
  color: #646464;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 0 15px;
  justify-content: space-between;
  box-sizing: border-box;
  background: #f5f5f5;
}

.contract-button {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.contract-button div {
  display: flex;
  box-sizing: border-box;
  padding: 0 7.5px;
  justify-content: center;
  align-items: center;
}

.contract-button img {
  height: 9px;
}


.contract-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 57px;
  background: #FFFFFF;
  padding: 0 15px;
  box-sizing: border-box;
  color: #323232;
  font-size: 17px;
  font-weight: 500;
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}

.contract-detail-reject {
  color: #FF6010;
  box-sizing: border-box;
  padding: 16px 15px;
  font-size: 16px;
  font-weight: 500;
  min-height: 78px;
  width: 100%;
  background: #FFF9ED;
}

.contract-detail-reject .text {
  font-weight: 500;
  -webkit-box-orient: vertical;
  font-size: 12px;
  line-height: 16px;
  margin-top: 11px;
  font-weight: 400;
}
.contract-detail-apply {
  background-color: #fff;
  padding: 10.5px 19px;
  box-shadow: 0px -1px 12px 0px rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 0;
  width: calc(100% - 30px);
}

.contract-detail-apply.border {
  box-shadow: 0 1.5px 5px 0 rgba(0, 0, 0, 0.06);
}

.contract-detail-apply .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 3px 0;
  color: #1a1a1a;
  font-size: 18px;
  font-weight: 600;
}

.contract-detail-apply .kv_item {
  box-sizing: border-box;
  padding: 24px 0;
  display: flex;
  align-items: center;
  min-height: 74.5px;
}

.contract-detail-apply .line {
  background: #eaeaea;
  height: 0.5px;
  margin: 0 -19px;
}

.payment-collection {
  margin-top: 10px;
  justify-content: space-between;
}

.am-progress-bar {
  height: 7.5px !important;
  background-image: linear-gradient(to right, #50A0FF, #50E5FF);
  border: none !important;
  border-radius: 7.5px;
}

.ant-timeline-item-tail {
  border-left: 2px solid red;
}

.contract-reject-container {
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding-top: 10px;
  position: relative;
  box-sizing: border-box;
}

.contract-reject-content {
  background: #fff;
  box-sizing: border-box;
  padding: 15px 15px 5px;
}

.contract-reject-content .am-list-item {
  padding-left: 0;
}

.contract-reject-content .am-textarea-control {
  padding-top: 4px;
}

.contract-reject-title,
.contract-agree-title {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #323232;
  line-height: 18px;
}

.contract-agree-title1{
  margin-top: 10px;
}

.ant-modal-footer {
  padding: 0;
  text-align: center;
}

.am-list-content {
  font-size: 16px !important;
}

.contract-require-item .am-list-content::after,
.contract-reject-title::after{
  content: '*';
  color: #E84E4E;
}

.contract-reject-button,
.contract-agree-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 15px;
  background: #fff;
}

/** agree*/
.contract-agree-tips {
  background: rgba(255, 96, 16, 0.06);
  height: 40px;
  color: #FF6010;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  padding-left: 15px;
  text-align: left;
}
/* 审批流程时间轴 */

.contract-step-container {
  position: relative;
  margin-top: 20px;
}

.contract-step-dot-container {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}

.contract-step-dot-img {
  width: 20px;
  height: 20px;
}


.contract-step-line {
  position: absolute;
  background: rgba(66, 120, 191, 0.45);
  height: calc(100% - 20px);
  width: 2px;
  top: 24px;
  left: 9px;
}

.contract-step-item-container {
  flex-grow: 1;
}

.contract-step-title {
  margin: 2px 0 7.5px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #323232;
  line-height: 16px;
}

.contract-enclosure {
  width: 46px;
  height: 46px;
  margin: 0 10px 3px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #323232;
  font-size: 12px;
  background: rgba(66, 120, 191, 0.05);
}

.contract-letter-view {
  width: 177px;
  display: flex;
  flex-direction: row;
}

.ap-modal .am-modal-button-group-h {
  border-top: none !important;
}

.ap-modal .am-modal-title {
  font-size: 16px !important;
}

.am-modal-transparent .am-modal-content {
  padding-top: 8px !important;
}

.ap-modal .am-textarea-control {
  padding: 8px 8px 27px !important;
}

.ap-modal .am-modal-button:nth-child(2) {
  color: #4278BF !important;
  border-left: none !important;
}

/* add by hfcai */

.am-picker-popup-item.am-picker-popup-header-left {
  color: #999999;
  font-size: 16px;
}

.am-picker-popup-item.am-picker-popup-header-right {
  font-size: 16px;
  color: #4378BE;
}

.am-button.am-button-primary{
  background-color: #4378BE !important;
}